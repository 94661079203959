import {
  requestKeys,
  useVuexFirebaseRequests,
} from '~/components/composables/useVuexFirebaseRequests';
import { agreementSourceTypes } from '~/utils/constants.js';

const requestGetTermination = 'getTermination';
const requestPostTermination = 'postTermination';

const requests = [requestGetTermination, requestPostTermination];

const firebaseRequestsSetup = useVuexFirebaseRequests(requests);

export const state = () => ({ ...firebaseRequestsSetup.state });
export const getters = { ...firebaseRequestsSetup.getters };
export const mutations = { ...firebaseRequestsSetup.mutations };

export const actions = {
  ...firebaseRequestsSetup.actions,
  /**
   * @param {string} agreementId
   * @param {string} annexId
   */
  getTermination: function ({ dispatch }, { agreementId }) {
    const getAnnexKeys = requestKeys(requestGetTermination);
    const request = {
      agreementId: agreementId,
      // get: ['ACTIVE', 'PENDING', 'TERMINATIONS', 'TYPES'],
      get: ['ACTIVE', 'PENDING', 'TYPES'],
    };
    return dispatch(getAnnexKeys.ACTION_MAKE_REQUEST, { request: request });
  },

  /**
   * @param {string} agreementId
   * @param {terminationType} terminationType
   * @param {string} endDate
   * @param {string} terminationDescription
   */
  postTermination: function (
    { dispatch },
    { agreementId, terminationType, endDate, terminationDescription },
  ) {
    const postTerminationKeys = requestKeys(requestPostTermination);
    const requestData = {
      endDate: endDate,
      description: terminationDescription,
    };
    const request = {
      //source: agreementSourceTypes.WEB,
      agreementId: agreementId,
      type: terminationType,
    };

    return dispatch(postTerminationKeys.ACTION_MAKE_REQUEST, {
      request: request,
      requestData: requestData,
    });
  },
};
